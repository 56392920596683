import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["index_table", "mapping_dropdown",
    "filter_deals", "filter_service_types", "filter_deal_status",
    "filter_suppliers", "filter_deal_period", "filter_cities",
    "filter_accounts"]


  connect() {
    if (this.hasIndex_tableTarget) {
      const url = this.data.get("table-load-url");
      this.createIndexTable(url);
    }
  
  }
  filterTable() {
    this.setFilterToTable();
  }

  setFilterToTable() {
    let table = this.index_tableTarget.tabulator;
    let filters = []

    // let search_data = []
    if (this.hasFilter_dealsTarget) {
      filters.push({
        field: "deal_ids",
        type: "=",
        value: this.filter_dealsTarget.choices.getValue(true)
      })
    }

    if (this.hasFilter_deal_periodTarget) {
      filters.push({
        field: "deal_period",
        type: "=",
        value: this.filter_deal_periodTarget.value
      })
    }

    if (this.hasFilter_citiesTarget) {
      filters.push({
        field: "city_ids",
        type: "=",
        value: this.filter_citiesTarget.choices.getValue(true)
      })
    }

    if (this.hasFilter_accountsTarget) {
      filters.push({
        field: "account_ids",
        type: "=",
        value: this.filter_accountsTarget.choices.getValue(true)
      })
    }

    if (this.hasFilter_deal_statusTarget) {
      filters.push({
        field: "deal_status",
        type: "=",
        value: this.filter_deal_statusTarget.choices.getValue(true)
      })
    }

    if (this.hasFilter_service_typesTarget) {
      filters.push({
        field: "service_type_ids",
        type: "=",
        value: this.filter_service_typesTarget.choices.getValue(true)
      })
    }

    if (this.hasFilter_suppliersTarget) {
      filters.push({
        field: "supplier_ids",
        type: "=",
        value: this.filter_suppliersTarget.choices.getValue(true)
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }


  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  openModalForm(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }  

  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });
      
      const mainTable = document.getElementById("supplier_invoices_table")
      const mainTabulator = mainTable.tabulator

      if (data.row_action == "delete") {
        const rowId = data.id;
        mainTabulator.deleteRow(rowId);
      } else {
        // update row data
        const rowData = data.data
        const row = mainTabulator.getRow(rowData.id)
        row.update(rowData)
      }

      window.layout_modal_md.hide();
    }
  }

  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    window.layout_modal_md.setContent(data.form_content)
  }

  onIsProcessedChanged(event) {
    event.currentTarget.disabled = true;
    let url = event.currentTarget.getAttribute("data-url");
    let data = { "deal_transaction": { "is_processed": event.currentTarget.checked ? "true" : "false" }}
    this.updateIsProcessedStatus(url, data, event.currentTarget);
  }

  async updateIsProcessedStatus(url, data, element) {
    fetch(url, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'same-origin',
      body: JSON.stringify(data)
    }).then((response) => {
      if (response.status == 200) {
        
        response.text().then((content) => {
          data = JSON.parse(content);

          window.vNotify.success({ text: data.notification_message, title: data.notification_title });

          // update row data
          const rowData = data.data
          const mainTable = document.getElementById("supplier_invoices_table")
          const mainTabulator = mainTable.tabulator
          const row = mainTabulator.getRow(rowData.id)
          row.update(rowData)
        })
      } else {
        response.text().then((content) => {
          content = JSON.parse(content);
          window.vNotify.error({ text: content.notification_message, title: content.notification_title });

          element.disabled = false;
          element.checked = !element.checked;
        })
      }
    });
  }

  async createIndexTable(url) {
    const table_target = this.index_tableTarget;
    const columns = this.data.get("table-columns");

    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");


    let table_props = {
      layout: "fitDataTable",
      virtualDomBuffer: 4000,
      index: "id",
      height: (height - 180) + "px",
      persistenceID: "supplier_invoices_table",
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],

      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",

      ajaxResponse:function(url, params, response){
        const table = document.getElementById("supplier_invoices_table")

        return response;
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }



}