import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["marketing_percent", "price"]

  connect() {
  }

  openModalForm(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }

  toggleAutoCalculated(event) {
    let is_auto_calculted = event.currentTarget.checked;

    if (is_auto_calculted) {
      this.marketing_percentTarget.disabled = false;
      this.priceTarget.disabled = true;
      this.priceTarget.value = "";
    } else {
      this.marketing_percentTarget.disabled = true;
      this.marketing_percentTarget.value = "";
      this.priceTarget.disabled = false;
    }

  }

  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;

    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });
      // update table data
      let marketingsBody = document.getElementById("deal_services_marketing")
      if (marketingsBody) {
        marketingsBody.innerHTML = data.table_content
      }
      window.layout_modal_md.hide();
    }
  }

  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    // modal set content
    window.layout_modal_md.setContent(data.form_content)
  }


}