import { ApplicationController } from "../application_controller";
import BSN from "bootstrap.native";
import { getMetaValue } from "helpers";

const TABLE_ID = "deal_foods_form_table"

export default class extends ApplicationController {
  static targets = [
    "service_tr",
    "save_and_new",
    "save"
  ]

  connect() {
    //
    // Service TR
    //
    if (this.hasService_trTarget) {
      this.setFormTotalHtml()
    }
  }

  //
  // Actions
  //
  collapseServices(event) {
    event.stopImmediatePropagation();
    let target = event.currentTarget;
    let collapse = new BSN.Collapse(target)
    if (target.classList.contains("collapsed"))
      collapse.show();
    else collapse.hide();
  }

  removeService(event) {
    // event.preventDefault();
    event.stopImmediatePropagation();
    let response = confirm(event.currentTarget.getAttribute("data-confirm-message"));
    if (response) {
      const tr = event.currentTarget.closest("tr");
      let id = event.currentTarget.getAttribute("data-id")
      if (id) {
        let destroy_target = document.getElementById(id)
        if (destroy_target) destroy_target.value = true
      }
      tr.remove();
      this.reindexTableRows();
      this.setFormTotalHtml();
      this.enableDisableSaveButtons()
    }
  }

  statusChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  priceChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  countChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  async addSelectedService(event) {
    event.preventDefault();
    const btn_target = event.currentTarget
    const url = btn_target.getAttribute("data-url")
    if (!btn_target.classList.contains("disabled")) {
      await this.addServiceRequest(url)
      this.enableDisableSaveButtons()
    }
  }

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  // private

  //
  // enable_disable buttons
  //
  enableDisableSaveButtons() {
    const save_and_new_btn = document.getElementById("foods_save_and_new")
    const save_btn = document.getElementById("foods_save")
    const empty_form_info = document.getElementById("foods_empty_form_info")
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    if (rows.length > 0) {
      if (save_and_new_btn) save_and_new_btn.removeAttribute("disabled")
      save_btn.removeAttribute("disabled")
      empty_form_info.classList.add("hidden")
    } else {
      if (save_and_new_btn) save_and_new_btn.setAttribute("disabled", "disabled")
      save_btn.setAttribute("disabled", "disabled")
      empty_form_info.classList.remove("hidden")
    }
  }

  //
  // reindex table
  //
  reindexTableRows() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1
    }
  }

  //
  // set tr sum amount
  //
  setTrTotalHtml(tr) {
    const totals_hash = this.calculateTrSum(tr);
    const total_main_value_html = tr.querySelector(".total_main_value")
    total_main_value_html.innerHTML = super.setNumericFormat(totals_hash["main"]);

    const total_additional_value_html = tr.querySelector(".total_additional_value")
    total_additional_value_html.innerHTML = super.setNumericFormat(totals_hash["additional"]);
  }

  //
  // calculate tr sum amount
  //
  calculateTrSum(tr) {
    const main_additional_rate = tr.getAttribute("data-main-additional-rate").replace(",", '.').replace(/[^\d.-]/g, '');
    const service_currency_to_additional_rate = tr.getAttribute("data-service-currency-to-additional-rate").replace(",", '.').replace(/[^\d.-]/g, '');
    const service_currency_to_main_rate = tr.getAttribute("data-service-currency-to-main-rate").replace(",", '.').replace(/[^\d.-]/g, '');

    const price_target = tr.querySelector("input.price_input")
    const count_target = tr.querySelector("input.count_input")

    const price = parseFloat(price_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))
    const count = parseFloat(count_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))

    let main_total = price * count * service_currency_to_main_rate
    let additional_total = price * count * service_currency_to_additional_rate

    return {
      "main": main_total,
      "additional": additional_total
    }
  }

  //
  // итого для формы
  //
  setFormTotalHtml() {
    const food_form_total_main_target = document.getElementById("food_form_total_main_value")
    const food_form_total_additional_target = document.getElementById("food_form_total_additional_value")
    const totals_hash = this.calculateFormTotal();

    food_form_total_main_target.innerHTML = super.setNumericFormat(totals_hash["main"]);
    food_form_total_additional_target.innerHTML = super.setNumericFormat(totals_hash["additional"]);
  }

  //
  // расчет итого по строкам таблицы
  //
  calculateFormTotal() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].rows
    let totals_hash = {
      "main": 0,
      "additional": 0
    }
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const status_target = tr.querySelector("select.status_select")

      // не суммируем отмененые
      if (status_target.value != "canceled") {
        let row_totals_hash = this.calculateTrSum(tr);
        totals_hash["main"] += row_totals_hash["main"]
        totals_hash["additional"] += row_totals_hash["additional"]
      }
    }
    return totals_hash
  }

  async addServiceRequest(url) {
    let service_input_id = "deal_services_add_new_service_id"
    let service_target = document.getElementById(service_input_id)
    if (service_target.value) {
      const data = new FormData();
      data.append("supplier_service_id", service_target.value);

      await fetch(url, {
        body: data,
        method: "POST",
        dataType: "text/javascript",
        credentials: "include",
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token")
        },
      }).then(function (response) {
        return response.text()
      }).then(text => {
        const table = document.getElementById(TABLE_ID)
        const rows_count = table.tBodies[0].querySelectorAll("tr:not(.hidden)").length + 1;
        let html = text.replace(/NEW_ROW_INDEX/g, rows_count)
        table.tBodies[0].insertAdjacentHTML("beforeend", html);
      })

    }
  }

}
