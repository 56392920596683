import Choices from "choices.js";
import BSN from "bootstrap.native";
import AutoNumeric from 'autonumeric';
import vNotify from "../vanilla-notify"
import { getMetaValue } from "helpers";
import { ApplicationController } from "../application_controller";


export default class extends ApplicationController {
  static targets = [
    "main_form",
    "choices_supplier",
    "choices_service",
    "form_count",
    "form_price",
    "form_total"
  ]

  connect() {
    if (this.hasChoices_supplierTarget) this.initSupplierChoices();
    if (this.hasChoices_serviceTarget) this.initServiceChoices();
  }

  //
  // Actions
  //
  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }
  //
  countChange(event) {
    event.preventDefault();
    this.setTotal(event.currentTarget.closest("form"))
  }

  //
  priceChange(event) {
    event.preventDefault();
    this.setTotal(event.currentTarget.closest("form"))
  }

  //
  totalChange(event) {
    event.preventDefault();
    const totalTarget = event.currentTarget
    const countTarget = this.form_countTarget
    const priceTarget = this.form_priceTarget

    let total = parseFloat(totalTarget.value.replace(",", '.').replace(/[^\d.-]/g, '')) || 0
    let count = parseFloat(countTarget.value.replace(",", '.').replace(/[^\d.-]/g, '')) || 0
    let price = 0
    if (count > 0) price = total / count

    priceTarget.numeric.set(price);
    return false;
  }


  //
  changeCurrency(event) {
    const form = event.currentTarget.closest("form");
    const currency = event.currentTarget.getAttribute("value");
    const formCurrencyTexts = form.querySelectorAll(".modal_currency_text");
    formCurrencyTexts.forEach(el => {
      el.innerHTML = currency;
    });
  }

  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });
      // update tab data
      let foreignSupplierBody = document.getElementById("deal_services_foreign_supplier")
      if (foreignSupplierBody) foreignSupplierBody.innerHTML = data.table_content
      window.layout_modal_md.hide();
    }
  }

  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    // modal set content
    window.layout_modal_md.setContent(data.form_content)
  }


  //
  // private
  //

  setTotal(form) {
    const priceTarget = form.querySelector("input.price_input")
    const countTarget = form.querySelector("input.count_input")
    const totalTarget = form.querySelector("input.total_input")

    let price = parseFloat(priceTarget.value.replace(",", '.').replace(/[^\d.-]/g, '')) || 0
    let count = parseFloat(countTarget.value.replace(",", '.').replace(/[^\d.-]/g, '')) || 0
    let total = price * count

    totalTarget.numeric.set(total);
  }


  // при смене услуги меняем данные в форме
  setFormService(service_choice_target_id) {
    const service_choice_target = document.getElementById(service_choice_target_id)
    if (service_choice_target) {
      const choice = service_choice_target.choices
      const valueJson = choice.getValue()
      if (valueJson["customProperties"]) {
        const form = document.getElementById("deal_services_foreign_suppliers_form")
        const currencyTarget = form.querySelector("ul.nav.nav-radio.nav-radio--group input[type='radio']:checked")
        const priceInMain = parseFloat(valueJson["customProperties"]["price"]) || 0
        const rateToMain = parseFloat(currencyTarget.getAttribute("data-rate-to-main").replace(",", '.').replace(/[^\d.-]/g, ''))
        const servicePriceInSelectedCurrency = priceInMain / rateToMain
        const priceTarget = form.querySelector("input.price_input")
        priceTarget.numeric.set(servicePriceInSelectedCurrency);
      }
    }
  }


  initSupplierChoices() {
    //
    // выбор поставщика, обновляем select для списка услуг
    //
    const target = this.choices_supplierTarget;
    const options = JSON.parse(target.dataset.options || null);
    const selected = JSON.parse(target.dataset.selected || null);

    let reload_services = this.getSupplierServicesData
    let choice = new Choices(target, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchChoices: true,
      searchFields: ["customProperties.cyr", "customProperties.lat"],
      searchResultLimit: 1,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute

    target.choices = choice;
    let reload_choices_id = target.getAttribute("data-reload-target-id")

    if (reload_choices_id) {
      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          const reload_el = document.getElementById(reload_choices_id)
          if (reload_el) {
            reload_services(this, event.detail.value);
          }
        },
        false,
      );
    }
    if (options) target.choices.setChoices(options, "value", "label", false)
    if (selected) target.choices.setChoiceByValue(selected)
    this.addValidateTrigger(target, target.choices)
  }

  initServiceChoices() {
    let set_form_service = this.setFormService
    let set_form_total = this.setTotal
    let choice = new Choices(this.choices_serviceTarget, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnInit: function () {
        if (this.passedElement.element.getAttribute("data-disable") == "true") {
          this.disable();
        }
      }
    });
    this.choices_serviceTarget.choices = choice
    const id = this.choices_serviceTarget.getAttribute("id")
    const form = this.main_formTarget;

    choice.passedElement.element.addEventListener(
      "change",
      function (event) {
        set_form_service(id)
        set_form_total(form)
      },
      false,
    );

    this.addValidateTrigger(this.choices_serviceTarget, this.choices_serviceTarget.choices)
  }

  //
  //
  async getSupplierServicesData(target, supplier_id) {
    let reload_field_id = target.getAttribute("data-reload-target-id");
    let services_url = target.getAttribute("data-services-url");
    let reload_field = document.getElementById(reload_field_id);

    if (reload_field) {

      let reload_choice = reload_field.choices;
      reload_choice.clearInput();
      reload_choice.clearStore();
      reload_choice.clearChoices();
      reload_choice.setChoiceByValue(null);

      if (supplier_id) {
        reload_choice.setChoices(async () => {
          try {
            const items = await fetch(services_url + "?supplier_id=" + supplier_id);
            return items.json();
          } catch (err) {
            // console.error(err);
          }
        });
        reload_choice.showDropdown();
      }
    }
  }


  messageText(key) {
    let locale = document.body.getAttribute("data-lang");
    const message_list = {
      "ru": {
        loadingText: "Загрузка...",
        noResultsText: "Ничего не найдено",
        noChoicesText: "Нет вариантов выбора",
        itemSelectText: "Нажмите для выбора"
      },
      "en": {
        loadingText: "Loading...",
        noResultsText: "No results found",
        noChoicesText: "No choices to choose from",
        itemSelectText: "Press to select"
      }
    }
    return message_list[locale][key];
  }

  addValidateTrigger(html_element, choices_object) {
    let form, controller, fieldContainer;
    form = html_element.closest('form')
    if (form != undefined)
      controller = this.formController(form)
    if (controller != undefined)
      fieldContainer = html_element.closest('.form-group');

    if (controller != undefined && fieldContainer != undefined) {
      choices_object.passedElement.element.addEventListener(
        'change',
        function (event) {
          controller.checkChoicesMulti(fieldContainer);
        },
        false,
      );
    }
  }

  formController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "layout--form")
  }

}

