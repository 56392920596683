import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js"
import { English } from "flatpickr/dist/l10n/default.js";
import { ApplicationController } from "../application_controller";
import BSN from "bootstrap.native";
import Choices from "choices.js";
import { getMetaValue } from "helpers";

const TABLE_ID = "deal_guides_form_table"
const NOT_SUM_STATUTES = ["canceled", "rejected"]

export default class extends ApplicationController {
  static targets = [
    "main_form",
    "save_and_new",
    "save",
    "choices_deal_service_guides_search",
    "choices_deal_guides_service_search",
    "table",
    "daterange_period"

  ]
  //
  initialize() {
    //
    // choices_deal_service_tickets_city_search
    // монументы, после выбора горда меняем список доступных монументов,
    // добавляем все объязательные монументы с этого города
    //
    if (this.hasChoices_deal_service_tickets_city_searchTarget) {
      let form, tickets_controller;
      form = this.choices_deal_service_tickets_city_searchTarget.closest("form")
      tickets_controller = this.ticketsController(form)

      let target = this.choices_deal_service_tickets_city_searchTarget
      let choice = new Choices(this.choices_deal_service_tickets_city_searchTarget, {
        removeItemButton: false,
        removeItems: false,
        searchEnabled: true,
        placeholder: true,
        loadingText: this.messageText("loadingText"),
        noResultsText: this.messageText("noResultsText"),
        noChoicesText: this.messageText("noChoicesText"),
        itemSelectText: this.messageText("itemSelectText")
      });
      // set as input attribute
      this.choices_deal_service_tickets_city_searchTarget.choices = choice;

      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          if (event.detail.value) {
            tickets_controller.addCityTickerServices(target, event.detail.value)
          }
          tickets_controller.addCityTickersToServicesSelect(this, event.detail.value);
        },
        false,
      );

      this.addValidateTrigger(this.choices_deal_service_tickets_city_searchTarget, this.choices_deal_service_tickets_city_searchTarget.choices)
    }

    if (this.hasChoices_deal_tickets_service_searchTarget) {
      let choice = new Choices(this.choices_deal_tickets_service_searchTarget, {
        removeItemButton: false,
        removeItems: false,
        searchEnabled: true,
        placeholder: true,
        loadingText: this.messageText("loadingText"),
        noResultsText: this.messageText("noResultsText"),
        noChoicesText: this.messageText("noChoicesText"),
        itemSelectText: this.messageText("itemSelectText")
      });
      // set as input attribute
      this.choices_deal_tickets_service_searchTarget.choices = choice;

      let btn_id = this.choices_deal_tickets_service_searchTarget.getAttribute("data-controll-btn")

      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          const btn = document.getElementById(btn_id)
          if (btn) {
            if (event.detail.value) {
              btn.classList.remove("disabled")
            } else {
              btn.classList.add("disabled")
            }
          }
        },
        false,
      );

      this.addValidateTrigger(this.choices_deal_tickets_service_searchTarget, this.choices_deal_tickets_service_searchTarget.choices)
    }

  }

  connect() {
    // table
    if (this.hasTableTarget) {
      this.setFormTotalHtml()
      this.enableDisableSaveButtons()
    }
    if (this.hasDaterange_periodTarget) {
      //
      // set locale
      //
      let app_locale = document.querySelector('body').dataset.lang;
      let locales = {
        "ru": {
          locales: Russian,
          date_format: "d-m-Y",
          datetime_format: "d-m-Y H:i"
        },
        "en": {
          locales: English,
          date_format: "Y-m-d",
          datetime_format: "Y-m-d H:i"
        }
      }

      //
      // locale Options
      //
      const locale_options = locales[app_locale];

      this.daterange_period(this.daterange_periodTarget, locale_options);
    }

  }


  //
  // Form Actions
  //
  //
  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  //
  // addSelectedService
  //
  async addSelectedService(event) {
    event.preventDefault();
    const btn_target = event.currentTarget
    const url = btn_target.getAttribute("data-url")
    if (!btn_target.classList.contains("disabled")) {
      await this.addServiceRequest(url)
      this.enableDisableSaveButtons()
    }
  }
  //
  // Actions
  //
  collapseServices(event) {
    event.stopImmediatePropagation();
    let target = event.currentTarget;
    let collapse = new BSN.Collapse(target)
    if (target.classList.contains("collapsed"))
      collapse.show();
    else collapse.hide();
  }

  removeService(event) {
    event.stopImmediatePropagation();
    let response = confirm(event.currentTarget.getAttribute("data-confirm-message"));
    if (response) {
      const tr = event.currentTarget.closest("tr");
      let id = event.currentTarget.getAttribute("data-id")
      if (id) {
        let destroy_target = document.getElementById(id)
        if (destroy_target) destroy_target.value = true
      }
      tr.remove();
      this.reindexTableRows();
      this.setFormTotalHtml();
      this.enableDisableSaveButtons()
    }
  }

  statusChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  priceChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  countChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  //
  // Private
  //

  // добавление услуги
  async addServiceRequest(url) {
    let service_input_id = "deal_services_guide_services_id"
    let service_target = document.getElementById(service_input_id)
    if (service_target.value) {
      const data = new FormData();
      data.append("supplier_service_id", service_target.value);

      await fetch(url, {
        body: data,
        method: "POST",
        dataType: "text/javascript",
        credentials: "include",
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token")
        },
      }).then(function (response) {
        return response.text()
      }).then(text => {
        const table = document.getElementById(TABLE_ID)
        const rows_count = table.tBodies[0].querySelectorAll("tr:not(.hidden)").length + 1;
        let html = text.replace(/NEW_ROW_INDEX/g, rows_count)
        table.tBodies[0].insertAdjacentHTML("beforeend", html);

        this.reindexTableRows();
        this.setFormTotalHtml();

      })

    }
  }

  //
  // расчет итого по строкам таблицы
  //
  calculateFormTotal() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].rows
    const array1 = [1, 2, 3];

    let totals_hash = {
      "main": 0,
      "additional": 0
    }
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const status_target = tr.querySelector("select.status_select")

      // не суммируем отмененые
      if (!NOT_SUM_STATUTES.includes(status_target.value)) {
        let row_totals_hash = this.calculateTrSum(tr);
        totals_hash["main"] += row_totals_hash["main"]
        totals_hash["additional"] += row_totals_hash["additional"]
      }
    }
    return totals_hash
  }

  //
  // set tr sum amount
  //
  setTrTotalHtml(tr) {
    const totals_hash = this.calculateTrSum(tr);
    const total_main_value_html = tr.querySelector(".total_main_value")
    total_main_value_html.innerHTML = super.setNumericFormat(totals_hash["main"]);

    const total_additional_value_html = tr.querySelector(".total_additional_value")
    total_additional_value_html.innerHTML = super.setNumericFormat(totals_hash["additional"]);
  }

  //
  // calculate tr sum amount
  //
  calculateTrSum(tr) {
    const main_additional_rate = tr.getAttribute("data-main-additional-rate").replace(",", '.').replace(/[^\d.-]/g, '');
    const service_currency_to_additional_rate = tr.getAttribute("data-service-currency-to-additional-rate").replace(",", '.').replace(/[^\d.-]/g, '');
    const service_currency_to_main_rate = tr.getAttribute("data-service-currency-to-main-rate").replace(",", '.').replace(/[^\d.-]/g, '');

    const price_target = tr.querySelector("input.price_input")
    const count_target = tr.querySelector("input.count_input")

    const price = parseFloat(price_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))
    const count = parseFloat(count_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))

    let main_total = price * count * service_currency_to_main_rate
    let additional_total = price * count * service_currency_to_additional_rate

    // let main_total = price * count
    // let additional_total = main_total * main_additional_rate

    return {
      "main": main_total,
      "additional": additional_total
    }
  }


  //
  // итого для формы
  //
  setFormTotalHtml() {
    const food_form_total_main_target = document.getElementById("food_form_total_main_value")
    const food_form_total_additional_target = document.getElementById("food_form_total_additional_value")
    const totals_hash = this.calculateFormTotal();

    food_form_total_main_target.innerHTML = super.setNumericFormat(totals_hash["main"]);
    food_form_total_additional_target.innerHTML = super.setNumericFormat(totals_hash["additional"]);
  }


  //
  // reindex table
  //
  reindexTableRows() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1
    }
  }


  //
  // enable_disable buttons
  //
  enableDisableSaveButtons() {
    const save_and_new_btn = document.getElementById("guides_save_and_new")
    const save_btn = document.getElementById("guides_save")
    const empty_form_info = document.getElementById("guides_empty_form_info")
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    if (rows.length > 0) {
      if (save_and_new_btn) save_and_new_btn.removeAttribute("disabled")
      save_btn.removeAttribute("disabled")
      empty_form_info.classList.add("hidden")
    } else {
      if (save_and_new_btn) save_and_new_btn.setAttribute("disabled", "disabled")
      save_btn.setAttribute("disabled", "disabled")
      empty_form_info.classList.remove("hidden")
    }
  }


  // daterange_period
  daterange_period(field, locale_options) {
    let period = null;
    if (field.dataset.period != null) period = field.dataset.period.split("|")
    let min_date = null;
    let max_date = null;
    if (field.dataset.allowable_range != null) [min_date, max_date] = field.dataset.allowable_range.split("|")
    let set_year_limit = this.setYearLimit

    // let is_set_days_count = false
    let days_count_class = field.dataset.daysCountClass;
    let days_count_closest = field.dataset.daysCountClosest;


    const tr = field.closest("tr");
    let guides_controller = this.guidesController(tr)


    field.flatpickr = flatpickr(field, {
      mode: "range",
      dateFormat: locale_options["date_format"],
      defaultDate: period,
      minDate: min_date,
      maxDate: max_date,
      onChange: function (selectedDates, dateStr, instance) {
        const form_group = field.closest(".form-group")
        const start_at_field = form_group.querySelector("input.start_at")
        const end_at_field = form_group.querySelector("input.end_at")
        let start_at_value = ""
        let end_at_value = ""
        if (selectedDates.length == 0) {
          start_at_value = ""
          end_at_value = ""
        } else if (selectedDates.length == 1) {
          start_at_value = flatpickr.formatDate(selectedDates[0], "Y-m-d")
          end_at_value = flatpickr.formatDate(selectedDates[0], "Y-m-d")
        } else if (selectedDates.length == 2) {
          start_at_value = flatpickr.formatDate(selectedDates[0], "Y-m-d")
          end_at_value = flatpickr.formatDate(selectedDates[1], "Y-m-d")
        }
        if (start_at_field) start_at_field.value = start_at_value
        if (end_at_field) end_at_field.value = end_at_value

        if (days_count_class && days_count_closest) {
          const start_date = new Date(start_at_value).getTime();
          const end_date = new Date(end_at_value).getTime();
          // 1000 milliseconds * 60 minutes * 60 seconds * 24 hours;
          const days_count = Math.ceil((end_date - start_date) / (1000 * 3600 * 24) + 1);

          const closest_target = field.closest(days_count_closest)
          const days_count_field = closest_target.querySelector(days_count_class)
          if (days_count_field) {
            if (days_count_field.numeric) days_count_field.numeric.set(days_count)
            else days_count_field.value = days_count

            guides_controller.setTrTotalHtml(tr)
            guides_controller.setFormTotalHtml();
          }

        }


      },
      onClose: function (selectedDates, dateStr, instance) {
        if (selectedDates.length == 1) {
          instance.setDate([selectedDates[0], selectedDates[0]], true);
        }
      },
      onOpen: function (selectedDates, dateStr, instance) {
        set_year_limit(instance.currentYearElement)
      }
    });

    let startDate = field.getAttribute("data-start-date")
    let calendarValue = field.getAttribute("value")
    if (startDate != undefined && (calendarValue == '' || calendarValue == undefined )) {
      field.flatpickr.jumpToDate(startDate);
    }
  }

  guidesController(element) {
    return this.application.getControllerForElementAndIdentifier(element, "deals--guides")
  }

  // yearLimit
  setYearLimit(year_target) {
    if (year_target) {
      year_target.type = "text"
      year_target.pattern = "\d*"
      year_target.maxLength = 4
      year_target.addEventListener(
        "keydown",
        function (event) {
          const charCode = (event.which) ? event.which : event.keyCode
          if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
          }
          return true;
        }
      );
    }
  }

}
