import { ApplicationController } from "../application_controller";


export default class extends ApplicationController {
  static targets = ["tps_arrivals_table", "tps_departures_table"]


  connect() {
    if (this.hasTps_arrivals_tableTarget) {
      this.loadTPSTable(this.tps_arrivals_tableTarget);
    }

    if (this.hasTps_departures_tableTarget) {
      this.loadTPSTable(this.tps_departures_tableTarget);
    }
  }


  openModalForm(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModal(url);
  }


  async loadTPSTable(target) {
    const url = this.data.get("tab-content-url");

    fetch(url, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/html',
        'Accept': 'application/html'
      },
      credentials: 'same-origin'
    }).then((response) => {
      if (response.status == 200) {
        response.text().then((content) => {
          target.innerHTML = content;
        })
      }
    });
  }

}