import { ApplicationController } from "../application_controller";


export default class extends ApplicationController {
  static targets = ["main_form"]

  connect() {
    if (this.hasMain_formTarget) {
      this.disableUploadToTrix()
    }
  }

  disableUploadToTrix() {
    const form_target = this.main_formTarget;
    let trix_target = form_target.querySelector("trix-editor")
    let trix_editor = trix_target.editor

    let file_icon_target = form_target.querySelector(".trix-button-group--file-tools")
    if (file_icon_target) file_icon_target.classList.add("hidden")

    trix_target.addEventListener("trix-file-accept", function (event) {
      event.preventDefault()
      alert("File attachment not supported!")
    })
  }

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }

  // update deal service tr and sub table
  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });
      window.layout_modal_md.hide();
    }
  }

  // update deal service form
  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    // modal set content
    window.layout_modal_md.setContent(data.form_content)
  }

}