import { ApplicationController } from "../application_controller";
import Choices from "choices.js";
import BSN from "bootstrap.native";
import { getMetaValue } from "helpers";

const TABLE_ID = "deal_tickets_form_table"

export default class extends ApplicationController {
  static targets = [
    "table",
    "service_tr",
    "choices_deal_service_tickets_city_search",
    "choices_deal_tickets_service_search"
  ]
  //
  initialize() {
    // choices_deal_service_tickets_city_search
    // монументы, после выбора горда меняем список доступных монументов,
    // добавляем все объязательные монументы с этого города
    if (this.hasChoices_deal_service_tickets_city_searchTarget) { this.ticketsCitySearch(); return; }
    if (this.hasChoices_deal_tickets_service_searchTarget) { this.ticketsServiceSearch(); return; }
  }

  connect() {
    // table
    if (this.hasTableTarget) this.setFormTotalHtml()
  }

  //
  // Actions
  //
  //
  collapseServices(event) {
    event.stopImmediatePropagation();
    let target = event.currentTarget;
    let collapse = new BSN.Collapse(target)
    if (target.classList.contains("collapsed"))
      collapse.show();
    else collapse.hide();
  }

  removeService(event) {
    event.stopImmediatePropagation();
    let response = confirm(event.currentTarget.getAttribute("data-confirm-message"));
    if (response) {
      const tr = event.currentTarget.closest("tr");
      let id = event.currentTarget.getAttribute("data-id")
      if (id) {
        let destroy_target = document.getElementById(id)
        if (destroy_target) destroy_target.value = true
      }
      tr.remove();
      this.reindexTableRows();
      this.setFormTotalHtml();
      this.enableDisableSaveButtons()
    }
  }

  async addSelectedService(event) {
    event.stopImmediatePropagation();
    const btn_target = event.currentTarget
    const url = btn_target.getAttribute("data-url")
    if (!btn_target.classList.contains("disabled")) {
      await this.addServiceRequest(url)
      this.enableDisableSaveButtons()
    }
  }

  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  statusChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  priceChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  countChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setTrTotalHtml(tr);
    this.setFormTotalHtml();
  }

  // private
  ticketsServiceSearch() {
    const target = this.choices_deal_tickets_service_searchTarget;
    const options = JSON.parse(target.dataset.options || null);
    const selected = JSON.parse(target.dataset.selected || null);

    let choice = new Choices(target, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchFields: ["customProperties.cyr", "customProperties.lat"],
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute
    target.choices = choice;

    let btn_id = target.getAttribute("data-controll-btn")

    choice.passedElement.element.addEventListener(
      "change",
      function (event) {
        const btn = document.getElementById(btn_id)
        if (btn) {
          if (event.detail.value) {
            btn.classList.remove("disabled")
          } else {
            btn.classList.add("disabled")
          }
        }
      },
      false,
    );
    if (options) target.choices.setChoices(options, "value", "label", false)
    if (selected) target.choices.setChoiceByValue(selected)
    this.addValidateTrigger(target, target.choices)
  }

  ticketsCitySearch() {
    const target = this.choices_deal_service_tickets_city_searchTarget;
    const options = JSON.parse(target.dataset.options || null);
    const selected = JSON.parse(target.dataset.selected || null);

    let form, tickets_controller;
    form = target.closest("form")
    tickets_controller = this.ticketsController(form)
    let choice = new Choices(target, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchFields: ["customProperties.cyr", "customProperties.lat"],
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute
    target.choices = choice;

    let add_required_monuments = document.getElementById("add_required_monuments");

    choice.passedElement.element.addEventListener(
      "change",
      function (event) {
        if (event.detail.value && add_required_monuments.checked) {
          tickets_controller.addCityTickerServices(target, event.detail.value)
        }
        tickets_controller.addCityTickersToServicesSelect(this, event.detail.value);
      },
      false,
    );
    if (options) target.choices.setChoices(options, "value", "label", false)
    if (selected) target.choices.setChoiceByValue(selected)
    this.addValidateTrigger(target, target.choices)
  }


  //
  // enable_disable buttons
  //
  enableDisableSaveButtons() {
    const save_and_new_btn = document.getElementById("tickets_save_and_new")
    const save_btn = document.getElementById("tickets_save")
    const empty_form_info = document.getElementById("tickets_empty_form_info")
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    if (rows.length > 0) {
      if (save_and_new_btn) save_and_new_btn.removeAttribute("disabled")
      save_btn.removeAttribute("disabled")
      empty_form_info.classList.add("hidden")
    } else {
      if (save_and_new_btn) save_and_new_btn.setAttribute("disabled", "disabled")
      save_btn.setAttribute("disabled", "disabled")
      empty_form_info.classList.remove("hidden")
    }
  }


  //
  // set tr sum amount
  //
  setTrTotalHtml(tr) {
    const totals_hash = this.calculateTrSum(tr);
    const total_main_value_html = tr.querySelector(".total_main_value")
    total_main_value_html.innerHTML = super.setNumericFormat(totals_hash["main"]);

    const total_additional_value_html = tr.querySelector(".total_additional_value")
    total_additional_value_html.innerHTML = super.setNumericFormat(totals_hash["additional"]);
  }

  //
  // calculate tr sum amount
  //
  calculateTrSum(tr) {
    const main_additional_rate = tr.getAttribute("data-main-additional-rate").replace(",", '.').replace(/[^\d.-]/g, '');
    const service_currency_to_additional_rate = tr.getAttribute("data-service-currency-to-additional-rate").replace(",", '.').replace(/[^\d.-]/g, '');
    const service_currency_to_main_rate = tr.getAttribute("data-service-currency-to-main-rate").replace(",", '.').replace(/[^\d.-]/g, '');

    const price_target = tr.querySelector("input.price_input")
    const count_target = tr.querySelector("input.count_input")

    const price = parseFloat(price_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))
    const count = parseFloat(count_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))

    let main_total = price * count * service_currency_to_main_rate
    let additional_total = price * count * service_currency_to_additional_rate

    return {
      "main": main_total,
      "additional": additional_total
    }
  }

  //
  // итого для формы
  //
  setFormTotalHtml() {
    const food_form_total_main_target = document.getElementById("food_form_total_main_value")
    const food_form_total_additional_target = document.getElementById("food_form_total_additional_value")
    // console.log(1)
    const totals_hash = this.calculateFormTotal();
    // console.log(totals_hash);

    food_form_total_main_target.innerHTML = super.setNumericFormat(totals_hash["main"]);
    food_form_total_additional_target.innerHTML = super.setNumericFormat(totals_hash["additional"]);
  }

  //
  // расчет итого по строкам таблицы
  //
  calculateFormTotal() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].rows
    let totals_hash = {
      "main": 0,
      "additional": 0
    }
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const status_target = tr.querySelector("select.status_select")

      // не суммируем отмененые
      // if (status_target.value != "canceled") {
        let row_totals_hash = this.calculateTrSum(tr);
        totals_hash["main"] += row_totals_hash["main"]
        totals_hash["additional"] += row_totals_hash["additional"]
      // }
    }
    return totals_hash
  }

  //
  // reindex table
  //
  reindexTableRows() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1
    }
  }

  // добавление услуги
  async addServiceRequest(url) {
    let service_input_id = "deal_services_add_new_entry_ticket_id"
    let service_target = document.getElementById(service_input_id)
    if (service_target.value) {
      const data = new FormData();
      data.append("supplier_service_id", service_target.value);

      await fetch(url, {
        body: data,
        method: "POST",
        dataType: "text/javascript",
        credentials: "include",
        headers: {
          "X-CSRF-Token": getMetaValue("csrf-token")
        },
      }).then(function (response) {
        return response.text()
      }).then(text => {
        const table = document.getElementById(TABLE_ID)
        const rows_count = table.tBodies[0].querySelectorAll("tr:not(.hidden)").length + 1;
        let html = text.replace(/NEW_ROW_INDEX/g, rows_count)
        table.tBodies[0].insertAdjacentHTML("beforeend", html);

        this.reindexTableRows();
        this.setFormTotalHtml();

      })

    }
  }


  // добавляем все момументы с города
  async addCityTickerServices(target, city_id) {
    const url = target.getAttribute("data-post-url")
    const table_id = target.getAttribute("data-table-id")
    const data = new FormData();
    data.append("city_id", city_id);

    let overlay = document.getElementById("deal_tickets_form_overlay")
    overlay.classList.remove("hidden")

    await fetch(url, {
      body: data,
      method: "POST",
      dataType: "text/javascript",
      credentials: "include",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      },
    }).then(function (response) {
      return response.text()
    }).then(text => {
      const table = document.getElementById(table_id)
      const rows_count = table.tBodies[0].querySelectorAll("tr:not(.hidden)").length + 1;
      let html = text.replace(/NEW_ROW_INDEX/g, rows_count)
      table.tBodies[0].insertAdjacentHTML("beforeend", html);
      this.reindexTableRows();
      this.setFormTotalHtml();
      this.enableDisableSaveButtons();
    })
    overlay.classList.add("hidden")
  }

  // в выбор монументов загружаем все монументы с этого города
  async addCityTickersToServicesSelect(target, city_id) {
    let reload_field_id = target.getAttribute("data-reload-target-id");
    let services_url = target.getAttribute("data-services-url");
    let reload_field = document.getElementById(reload_field_id);

    if (reload_field) {

      let reload_choice = reload_field.choices;
      reload_choice.clearInput();
      reload_choice.clearStore();
      reload_choice.clearChoices();
      reload_choice.setChoiceByValue(null);

      if (city_id) {
        reload_choice.setChoices(async () => {
          try {
            const items = await fetch(services_url + "?city_id=" + city_id);
            return items.json();
          } catch (err) {
          }
        });
        reload_choice.showDropdown();
      }
      let btn_id = reload_field.getAttribute("data-controll-btn")
      const btn = document.getElementById(btn_id)
      if (btn) btn.classList.add("disabled")
    }
  }

  // для селекта
  messageText(key) {
    let locale = document.body.getAttribute("data-lang");
    const message_list = {
      "ru": {
        loadingText: "Загрузка...",
        noResultsText: "Ничего не найдено",
        noChoicesText: "Нет вариантов выбора",
        itemSelectText: "Нажмите для выбора"
      },
      "en": {
        loadingText: "Loading...",
        noResultsText: "No results found",
        noChoicesText: "No choices to choose from",
        itemSelectText: "Press to select"
      }
    }
    return message_list[locale][key];
  }

  addValidateTrigger(html_element, choices_object) {
    let form, controller, fieldContainer;
    form = html_element.closest('form')
    if (form != undefined)
      controller = this.formController(form)
    if (controller != undefined)
      fieldContainer = html_element.closest('.form-group');

    if (controller != undefined && fieldContainer != undefined) {
      choices_object.passedElement.element.addEventListener(
        'change',
        function (event) {
          controller.checkChoicesMulti(fieldContainer);
        },
        false,
      );
    }
  }

  formController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "layout--form")
  }

  ticketsController(ticket_element) {
    return this.application.getControllerForElementAndIdentifier(ticket_element, "deals--tickets")
  }


}
